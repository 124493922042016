<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
      <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>Edit Blog Info</h2>
      </div>
      <div class="flexcircle">
        <router-link :to="{ name: 'blog_info', params: {id: $route.params.eid}}" class="btn btn-gray btn-subheader">Back</router-link>
        <!-- <button type="button" class="btn btn-vermilion btn-subheader">Add Physician</button> -->
      </div>
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="updatepersonalinfo">
        <div class="errorrow">
          <!-- <pre>{{errors}}</pre> -->
          <!-- <div class="errorsmall" v-if="signup.submitted && personalinfo.loginid.$error.pattren">Loginid is not valid</span>
            <span class="error errorbatch" ng-show="signup.submitted && personalinfo.loginid.$error.maxlength">Loginid is Exceded 20 Characters </span> -->
          <span class="errorsmall" v-show="error">{{errorreport}}</span>

        </div>
        <div class="row">

          <div class="col-sm-6 mb20">
            <label>Title</label>
              <input type="text" class="form-control" placeholder="Blog Title" name="Blog Title" id="btitle" :class="{ 'is-danger': errors.has( 'Blog Title')}" v-validate="'required'" v-model="blog.title">
              <span class="error_text" v-if="errors.has('Blog Title')">{{errors.first('Blog Title')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Blog Category</label>
              <multiselect v-model="selectedObjects" tag-placeholder="Add this as new category" placeholder="Search or add a category" label="name" track-by="name" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
          </div>



          <div class="col-12 mb-5">
            <label>Content</label>
            <vue-editor
    :customModules="customModulesForEditor"
    :editorOptions="editorSettings"
    v-model="blog.content"
    name="Info" id="info"
  >
  </vue-editor>
  <!-- <span class="error_text" v-if="errors.has('Info')">{{errors.first('Info')}}</span> -->
          </div>

<div class="col-12 mb20">
  <div class="flexrow ">
    <div class="subheader_flex">
      <h2>Profile Image </h2>
    </div>
    <div class="flexcircle">
      <div class="upload-btn-wrapper">
        <button class="btn btn-green">Update Media</button>
        <input type="file" id="files" ref="files"  v-on:change="handleFilesUpload($event)"  accept="application/pdf,image/tiff,image/jpeg,image/x-png,video/x-msvideo,video/x-mpeg2"/>
      </div>
    </div>
  </div>
  <div class="col-12 " style="display: grid;" v-if="files.length == 0 && blog.image !== null">
    <img :src="blog.image"   class="imagewidth img-fluid" width="300" height="300" v-on:click="$refs.files.click()" />
      <div class="filename subheader_flex pt-3">{{ blog.filename }} </div>
  </div>
  <div class="clearfix col-12 filebox" v-show="showstrsec" >
    <div class="uploadmess text-center" v-if="files.length === 0" v-on:click="$refs.files.click()">
        Please click here to browse media files
    </div>
  <div class="file-listing clearfix justify-content-center bg-transparent" v-for="(file, key) in files" v-bind:key="key">
      <div class="flexrow fixeddim">
        <!-- <div class="filename subheader_flex ">{{ file.filee.name }} </div> -->
        <img :ref="'image'" class="img-fluid"/>
        <video id="preview" width="600" :ref="'video'"  controls v-hide="videodiv">
Your browser does not support HTML5 video.
</video>
        <div class="file_btn flexcircle">
          <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
        </div>
      </div>

      <div class="filename subheader_flex pt-3" v-if="file.type == 'application/pdf'">{{ file.name }} </div>
</div>
  </div>
</div>
          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";
import Multiselect from 'vue-multiselect'
export default {
  name: 'edit_blog',
  components: {
    VueEditor,
    Multiselect
  },
  data () {
    return {
      customModulesForEditor: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "imageResize", module: ImageResize }
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      error: false,
      errorreport: '',
      blog: {},
      files: [],
      showloader: true,
      showstrsec: false,
      selectedObjects: [],
      selectedcategories: [],
      options: [],
      videodiv: true
    }
  },
  watch: {
  selectedObjects(newValues) {
    this.selectedcategories = newValues.map(obj => obj.name);
  }
},
  created () {
    this.loadinfo()
    this.loadusers()
  },
  methods: {
    loadusers () {
      this.photographers = []
      this.$http.get('/cmsmanagement/getblogcategories').then((res) => {
        let array = res.data.details.map(obj => obj.category)
        console.log(array)
        this.options = array.map(name => ({...this.options, name}));
        console.log(this.options);
      this.showloader = false
      })
    },
    addTag (newTag) {
     const tag = {
       name: newTag
     }
     this.options.push(tag)
     this.selectedObjects.push(tag)
   },
  loadinfo () {
    this.$http.get('/cmsmanagement/bloginfo/' + this.$route.params.eid).then((res) => {
      this.blog = res.data.details[0]
      let dynamicarray = this.blog.blogtype.split(',')
      this.selectedObjects = dynamicarray.map(name => ({...this.options, name}))
        this.selectedcategories = dynamicarray
        console.log(this.selectedcategories)
      if (this.blog.filename !== null){
      var extension = this.blog.filename.split('.').pop().toLowerCase();
      if (extension !== 'pdf' && extension !== 'doc') {
          // this.blog.image = this.blog.image;
      } else if(extension == 'pdf') {
        this.blog.image = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/195px-PDF_file_icon.svg.png";
      }
    } else {
        this.showstrsec = true
    }
      this.showloader = false
    })
  },
  updatepersonalinfo () {
    this.showloader = true
    this.blog.blogtype = this.selectedcategories
    console.log(this.selectedcategories)
    this.$validator.validateAll().then((result) => {
      if (result) {
        if (this.files.length !== 0) {
          let formData = new FormData()
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
            }
            formData.append('title', this.blog.title);
            formData.append('content', this.blog.content);
            formData.append('blogtype', this.blog.blogtype);
              this.$http.put('/cmsmanagement/updateblog/'+this.$route.params.eid,  formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'Blog info successfully updateded',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/blog_info/' + this.$route.params.eid)
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
          } else {
            this.$http.put('/cmsmanagement/updateblogwithoutimage/' + this.$route.params.eid, this.blog).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'Blog info successfully updateded',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/blog_info/' + this.$route.params.eid)
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
          }
      }  else {
        this.showloader = false
      }
    })
  },
  handleFilesUpload () {
    this.showstrsec = true
    // this.files = []
    let uploadedFiles = this.$refs.files.files
    /*
      Adds the uploaded file to the files array
    */
    for (var i = 0; i < uploadedFiles.length; i++) {
      this.files.push(uploadedFiles[i])
    }
    for (let i = 0; i < this.files.length; i++) {
      let reader = new FileReader();
     reader.onload = (e) => {
       var extension = this.files[i].name.split('.').pop().toLowerCase();
       if (extension !== 'pdf' && extension !== 'doc') {
           this.$refs.image[i].src = reader.result;
       } else if(extension == 'pdf') {
         this.$refs.image[i].src = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/195px-PDF_file_icon.svg.png";
       }else if(extension == 'mp4' || extension == 'm4v') {
         console.log('testvideo')
this.videodiv = false
          this.$refs.video[i].src = reader.result

       }
console.log(e)
      };

      reader.readAsDataURL(this.files[i]);
    }
  },
  removeFile (key) {
      this.showstrsec = true
    this.files.splice(key, 1)

  }
}
}
</script>

<style lang="css" scoped>

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
        <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>New Team Member</h2>
      </div>
      <div class="flexcircle">
        <router-link :to="{ name: 'TeamMembers'}" class="btn btn-gray btn-subheader">Back</router-link>
      </div>
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="addphotographer">
        <div class="errorrow">
          <span class="errorsmall" v-show="error">{{errorreport}}</span>
        </div>
        <div class="row">
          <div class="col-sm-6 mb20">
            <label>first Name</label>
              <input type="text" class="form-control" placeholder="First Name" name="firstname" id="firstname" :class="{ 'is-danger': errors.has( 'First Name')}" v-validate="'required'" v-model="member.first_name">
              <span class="error_text" v-if="errors.has('First Name')">{{errors.first('First Name')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Last Name</label>
              <input type="text" class="form-control" placeholder="Last Name" name="last name" id="lname" v-model="member.last_name">
          </div>
          <div class="col-sm-6 mb20">
            <label>Designation</label>
              <input type="text" class="form-control" placeholder="Designation" name="designation" id="designation" v-model="member.designation">
          </div>
          <div class="col-sm-6 mb20">
            <label>Email</label>
              <input type="text" class="form-control" placeholder="Email"  name="email id" id="email" v-model="member.email">
          </div>
          <div class="col-sm-6 mb20">
            <label>Phone</label>
              <input type="text" class="form-control" placeholder="Phone No"  name="Phone No" id="phoneno" v-model="member.phone">
          </div>
          <div class="col-sm-6 mb20">
            <label>Linked In</label>
              <input type="text" class="form-control" placeholder="Linked In"  name="linkedin" id="linkedin" v-model="member.linkedin">
          </div>
          <div class="col-12 mb-5">
            <label>About</label>
            <vue-editor
    :customModules="customModulesForEditor"
    :editorOptions="editorSettings"
    v-model="member.about"
    name="Info" id="info"
    :class="{ 'is-danger': errors.has( 'Info')}" v-validate="'required'"
  >
  </vue-editor>
  <span class="error_text" v-if="errors.has('Info')">{{errors.first('Info')}}</span>
          </div>
          <div class="col-12 mb20">
            <div class="flexrow ">
              <div class="subheader_flex">
                <h2>Profile Image </h2>
              </div>
              <div class="flexcircle">
                <div class="upload-btn-wrapper">
                  <button class="btn btn-green">Upload Image</button>
                  <input type="file" id="files" ref="files"  v-on:change="handleFilesUpload($event)" />
                </div>
              </div>
            </div>
            <div class="clearfix col-6 filebox profileimg " >
              <div class="uploadmess text-center" v-if="files.length === 0" v-on:click="$refs.files.click()">
              <font-awesome-icon icon="upload" size="6x" />
              </div>

                <div class="flexrow fixeddim profilefixeddim" v-for="(file, key) in files" v-bind:key="key">
                  <!-- <div class="filename subheader_flex ">{{ file.filee.name }} </div> -->
                  <img :ref="'image'" class="img-fluid"/>
                  <div class="file_btn flexcircle">
                    <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
                  </div>

              </div>
            </div>
          </div>

          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";
export default {
  name: 'NewTeamMember',
  components: {
    VueEditor
  },
  data () {
    return {
      content: '',
     customModulesForEditor: [
       { alias: "imageDrop", module: ImageDrop },
       { alias: "imageResize", module: ImageResize }
     ],
     editorSettings: {
       modules: {
         imageDrop: true,
         imageResize: {}
       }
     },
      error: false,
      errorreport: '',
      member: {
        first_name: '',
        last_name: '',
        designation: '',
        email: '',
        phone: '',
        about: '',
        linkedin: ''
      },
        files: [],
      showloader: false
    }
  },
  methods: {
    addphotographer () {
      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          let formData = new FormData()
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
              formData.append('first_name', this.member.first_name);
              formData.append('last_name', this.member.last_name);
                formData.append('designation', this.member.designation);
              formData.append('email', this.member.email);
              formData.append('phone', this.member.phone);
              formData.append('linkedin', this.member.linkedin);
              formData.append('about', this.member.about);
            this.$http.post('/cmsmanagement/addteammembers',  formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'New Team Member added successfully',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/team_members')
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
          } else {
            this.$http.post('/cmsmanagement/addteammemberswithoutimage',  this.member).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'New Team Member added successfully',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/team_members')
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            });
          }

        }  else {
          this.showloader = false
        }
      })
    },
    handleFilesUpload () {
      // this.files = []
      let uploadedFiles = this.$refs.files.files
      /*
        Adds the uploaded file to the files array
      */
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }
      for (let i = 0; i < this.files.length; i++) {
        let reader = new FileReader();
       reader.onload = (e) => {
          this.$refs.image[i].src = reader.result;
console.log(e)
        };

        reader.readAsDataURL(this.files[i]);
      }
    },
    removeFile (key) {
      this.files.splice(key, 1)
    }
  }
}
</script>

<style lang="css" scoped>

</style>

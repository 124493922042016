<template>
<div class="cards">
  <div class="inprocessdiv" v-show="showloader">
    <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
  </div>
  <div class="boxsh">
    <div class="flexrow_green mt20">
      <div class="subheader_green_flex">
        <h2>Service Info</h2>
      </div>
      <div class="flexcircle">

        <a @click="$router.push('/services')" class="btn btn-gray btn-subheader">Back</a>
        <!-- <button type="button" class="btn btn-vermilion btn-subheader">Add Physician</button> -->
      </div>
    </div>
    <div class="cardinner">
      <div class="row ">

          <div class="col-sm-6 mb20">
            <label> Title:</label>
            <br />
            <span class="spanblock">{{serv.title}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label> Status:</label>
            <br />
            <span class="spanblock text-capitalize">{{serv.status}}</span>
          </div>

          <div class="col-12 mb20">
            <label> Focused Areas:</label>
            <span class="spanblock" v-html="serv.focusedareas"></span>
          </div>




        <div class="col-12 text-right">
          <button type="button" class="btn btn-green mr-3" name="button" v-on:click="activate(serv)" v-if="serv.status == 'inactive'">Active</button>
         <button type="button" class="btn btn-vermilion mr-3" name="button" v-on:click="suspend(serv)" v-if="serv.status == 'active'">Suspend</button>

        <router-link :to="{ name: 'editservice', params: { eid: serv.serv_id }}"  class="btn btn-vermilion">Edit Info</router-link>
        </div>
      </div>
    </div>
  </div>


</div>
</template>

<script>
// import { mapGetters } from 'vuex'
  import _ from 'lodash';
export default {
  name: 'service_info',
  data () {
    return {
      serv: {
      },
      status: '',
      showloader: true

    }
  },
  mounted () {
    this.loadinfo()
  },
  methods: {
    sortArrays(arrays) {
            return _.orderBy(arrays, 'created_at');
        },
    loadinfo () {
      this.$http.get('/cmsmanagement/serviceinfo/' + this.$route.params.id).then((response) => {
        if (response.data.success === true) {
          this.showloader = false
          this.serv = response.data.details[0]
        } else {
          this.showloader = false
          this.serv = ''
        }
      })
    },

    // For suspend the Channel Partner...
      suspend (cpat) {
        let messagee = "Are you sure you want to Remove <b>" + cpat.title + '</b> from your services ?'
        this.$dialog.confirm(messagee,{
          loader: true,
          okText: "Proceed",
          cancelText: "Cancel",
          html: true
        })
        .then((dialog) => {
          this.$http.put('/cmsmanagement/updateserstatus/' + cpat.serv_id, {
            status: "inactive"
          }).then((res) => {
            console.log(res)
            // setTimeout(function() {
              this.$toast.open( {
                message: '<b>'+ cpat.title +'</b> successfully remove from services',
           position: 'top-right',
           duration: 5000,
           type: 'success'
           })

                   // }, 2000);
            this.loadinfo()
            dialog.close()
          })
        })
        .catch(() => {
          //console.log(this.$i18n.t('dataarray.xa271'))
        })
      },

    // For activate the Channel Partner...
    activate (cpat) {
      let messagee = "Are you sure you want to  add <b>" + cpat.title + '</b> to your services?'
      this.$dialog.confirm(messagee,{
        loader: true,
        okText: "Proceed",
        cancelText: "Cancel",
        html: true
      })
      .then((dialog) => {
        this.$http.put('/cmsmanagement/updateserstatus/' + cpat.serv_id, {
          status: "active"
        }).then((res) => {
          console.log(res)
          // setTimeout(function() {
            this.$toast.open( {
              message: '<b>'+ cpat.title +'</b> successfully added to services',
         position: 'top-right',
         duration: 5000,
         type: 'success'
         })

                 // }, 2000);
          this.loadinfo()
          dialog.close()
        })
      })
      .catch(() => {
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

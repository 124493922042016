<template>
<div class="cards">
  <div class="inprocessdiv" v-show="showloader">
    <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
  </div>
  <div class="boxsh">
    <div class="flexrow_green mt20">
      <div class="subheader_green_flex">
        <h2>Blog Info</h2>
      </div>
      <div class="flexcircle">

        <a @click="$router.push('/blogs')" class="btn btn-gray btn-subheader">Back</a>
        <!-- <button type="button" class="btn btn-vermilion btn-subheader">Add Physician</button> -->
      </div>
    </div>
    <div class="cardinner">
      <div class="row ">

          <div class="col-sm-6 mb20">
            <label> Title:</label>
            <br />
            <span class="spanblock">{{blog.title}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Blog Category:</label>
            <span class="spanblock">{{blog.blogtype}}</span>
          </div>
          <div class="col-12 mb20">
            <label> Content:</label>
            <span class="spanblock" v-html="blog.content"></span>
          </div>

          <div class="col-sm-12 mb20" style="display: grid;" v-if="blog.image !== null">
            <img :src="blog.image" alt="image"  class="imagewidth img-fluid" width="250" height="250" v-if="blog.extension !== 'mp4'"/>
      <video width="450" controls :src="blog.image" v-if="blog.extension == 'mp4'"></video>

            <div class="filename subheader_flex pt-3">{{ blog.filename }} </div>
          </div>



        <div class="col-12 text-right">
<button type="button" class="btn btn-green mr-3" name="button" v-on:click="deleteb(blog)" >Delete</button>
        <router-link :to="{ name: 'editblog', params: { eid: blog.blog_id }}"  class="btn btn-vermilion">Edit Info</router-link>
        </div>
      </div>
    </div>
  </div>


</div>
</template>

<script>
// import { mapGetters } from 'vuex'
  import _ from 'lodash';
export default {
  name: 'blog_info',
  data () {
    return {
      blog: {
      },
      images: [],
      status: '',
      showloader: true

    }
  },
  mounted () {
    this.loadinfo()
  },
  methods: {
    sortArrays(arrays) {
            return _.orderBy(arrays, 'created_at');
        },
    loadinfo () {
      this.$http.get('/cmsmanagement/bloginfo/' + this.$route.params.id).then((response) => {
        if (response.data.success === true) {
          this.showloader = false
          this.blog = response.data.details[0]
          if (this.blog.filename !== null){
          var extension = this.blog.filename.split('.').pop().toLowerCase();
          this.blog.extension = extension
          if (extension !== 'pdf' && extension !== 'doc' && extension !== 'mp4') {
              // this.blog.image = this.blog.image;
          } else if(extension == 'pdf') {
            this.blog.image = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/195px-PDF_file_icon.svg.png";
          }
        }
        } else {
          this.showloader = false
          this.blog = []
        }
      })
    },

    // For suspend the Channel Partner...
      suspend (cpat) {
        let messagee = "Are you sure you want to Suspend <b>" + cpat.first_name + '</b> from Team Members?'
        this.$dialog.confirm(messagee,{
          loader: true,
          okText: "Proceed",
          cancelText: "Cancel",
          html: true
        })
        .then((dialog) => {
          this.$http.put('/cmsmanagement/updatestatus/' + cpat.team_id, {
            status: "inactive"
          }).then((res) => {
            console.log(res)
            // setTimeout(function() {
              this.$toast.open( {
                message: '<b>'+cpat.first_name+'</b> successfully suspended from Team Members',
           position: 'top-right',
           duration: 5000,
           type: 'success'
           })

                   // }, 2000);
            this.loadinfo()
            dialog.close()
          })
        })
        .catch(() => {
          //console.log(this.$i18n.t('dataarray.xa271'))
        })
      },

      // For suspend the Channel Partner...
        deleteb (cpat) {
          let messagee = "Are you sure you want to Delete <b>" + cpat.title + '</b> ?'
          this.$dialog.confirm(messagee,{
            loader: true,
            okText: "Proceed",
            cancelText: "Cancel",
            html: true
          })
          .then((dialog) => {
            this.$http.delete('/cmsmanagement/deleteblog/' + cpat.blog_id, {
              status: "inactive"
            }).then((res) => {
              console.log(res)
              // setTimeout(function() {
                this.$toast.open( {
                  message: '<b>'+cpat.title+'</b> successfully deleted',
             position: 'top-right',
             duration: 5000,
             type: 'success'
             })

                     // }, 2000);
            this.$router.push('/blogs')
              dialog.close()
            })
          })
          .catch(() => {
            //console.log(this.$i18n.t('dataarray.xa271'))
          })
        },

    // For activate the Channel Partner...
    activate (cpat) {
      let messagee = "Are you sure you want to  make <b>" + cpat.first_name + '</b> as a Team Member?'
      this.$dialog.confirm(messagee,{
        loader: true,
        okText: "Proceed",
        cancelText: "Cancel",
        html: true
      })
      .then((dialog) => {
        this.$http.put('/cmsmanagement/updatestatus/' + cpat.team_id, {
          status: "active"
        }).then((res) => {
          console.log(res)
          // setTimeout(function() {
            this.$toast.open( {
              message: '<b>'+cpat.first_name+'</b> successfully addeded as a Team Member',
         position: 'top-right',
         duration: 5000,
         type: 'success'
         })

                 // }, 2000);
          this.loadinfo()
          dialog.close()
        })
      })
      .catch(() => {
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

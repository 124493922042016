<template>
  <!-- <animated-zoom-in> -->
  <div class="container-fluid">
		<div class="row main-content bg-success text-center">
			<div class="col-md-5 text-center company__info">
				<span class="company__logo">
            <img :src="require('@/assets/ft-logo.png')" class="img-fluid">
        </span>
				<!-- <h4 class="company_title">Your Company Logo</h4> -->
			</div>
			<div class="col-md-7 col-xs-12 col-sm-12 login_form p-3">
				<div class="container-fluid">
					<div class="row justify-content-center">
						<h2>Log In</h2>
					</div>
					<div class="row d-block">
						<div class="errormess">
							<div class="error" v-if="error">{{ error }}</div>
						</div>
						<form class="form-group form-signin" @submit.prevent="login">
							<div class="row">
								<input v-model="email" type="text" id="inputEmail"  name="email id" class="form__input" placeholder='Email' v-validate="'required'">
								<div class="error_text" v-if="errors.has('email id')">{{errors.first('email id')}}</div>
							</div>
							<div class="row">
								<!-- <span class="fa fa-lock"></span> -->
								<input v-model="password" type="password" name="password" class="form__input" :class="{'is-danger': errors.has('password')}" placeholder='Password' v-validate="'required'">
<div class="error_text" v-if="errors.has('password')">{{errors.first('password')}}</div>
							</div>
							<!-- <div class="row">
								<input type="checkbox" name="remember_me" id="remember_me" class="">
								<label for="remember_me">Remember Me!</label>
							</div> -->
							<div class="row justify-content-center">
								<input type="submit" value="Submit" class="btn loginbtn">
							</div>
						</form>
					</div>
					<!-- <div class="row">
						<p>Don't have an account? <a href="#">Register Here</a></p>
					</div> -->
				</div>
			</div>
		</div>
	</div>
  <!-- <div class="loginbox">
  <div class="logininnerbox">
    <div class="login_logo">
      <img :src="require('@/assets/nmlogo.png')" class="img-fluid">
    </div>
    <div class="boxsh">
      <div class="login_header">
        <h2 class="form-heading">Login </h2>
      </div>

        <form class="form-signin " @submit.prevent="login">

          <div class="login_area">
            <div class="errormess">
              <div class="error" v-if="error">{{ error }}</div>
            </div>
            <div class="mb20">
              <float-label>
                <input v-model="email" type="text" id="inputEmail"  name="email id" class="form-control" placeholder='Email' v-validate="'required'">
              </float-label>
              <div class="error_text" v-if="errors.has('email id')">{{errors.first('email id')}}</div>
            </div>

            <div class="inputfields">
              <float-label>
                <input v-model="password" type="password" name="password" class="form-control" :class="{'is-danger': errors.has('password')}" placeholder='Password' v-validate="'required'">
              </float-label>
              <div class="error_text" v-if="errors.has('password')">{{errors.first('password')}}</div>
            </div>
          </div>
          <button class="btn btn-green btn-block mb20" type="submit">Login</button>
        </form>
    </div>
  </div>

</div> -->
<!-- </animated-zoom-in> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'LoginPage',
  data () {
    return {
      email: '',
      password: '',
      error: false,
      showloader: false
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ])
  },
  methods: {
    ...mapActions([
      'setToken',
      'setUser'
    ]),
    login () {
      this.showloader = true
      this.$validator.validateAll().then((res) => {
        if (res) {
          this.$http.post('/auth/authenticate', { email: this.email, password: this.password })
            .then((res) => {
              if (res.data.success === true) {
                this.error = false
                this.setToken(res.data.token)
                localStorage.setItem('token', res.data.token)
                this.setUser(res.data.details)
                  this.$router.push('/team_members')
              } else {
                this.showloader = false
                this.error = "Login failed!"
              }
            })
            .catch(
              // this.showloader = false
            )
        } else {
          this.showloader = false
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>

<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
      <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>Edit Service Info</h2>
      </div>
      <div class="flexcircle">
        <router-link :to="{ name: 'service_info', params: {id: $route.params.eid}}" class="btn btn-gray btn-subheader">Back</router-link>
        <!-- <button type="button" class="btn btn-vermilion btn-subheader">Add Physician</button> -->
      </div>
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="updatepersonalinfo">
        <div class="errorrow">
          <!-- <pre>{{errors}}</pre> -->
          <!-- <div class="errorsmall" v-if="signup.submitted && personalinfo.loginid.$error.pattren">Loginid is not valid</span>
            <span class="error errorbatch" ng-show="signup.submitted && personalinfo.loginid.$error.maxlength">Loginid is Exceded 20 Characters </span> -->
          <span class="errorsmall" v-show="error">{{errorreport}}</span>

        </div>
        <div class="row">

          <div class="col-sm-6 mb20">
            <label>Title</label>
              <input type="text" class="form-control" placeholder="Service Title" name="Service Title" id="stitle" :class="{ 'is-danger': errors.has( 'Service Title')}" v-validate="'required'" v-model="serv.title">
              <span class="error_text" v-if="errors.has('Service Title')">{{errors.first('Service Title')}}</span>
          </div>
          <div class="col-12 mb-5">
            <label>Focused Areas</label>
            <vue-editor
    v-model="serv.focusedareas"
    name="Focusedareas" id="fareas" class="focusar"
  >
  </vue-editor>
  <!-- <span class="error_text" v-if="errors.has('Info')">{{errors.first('Info')}}</span> -->
          </div>

          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: 'edit_service',
  components: {
    VueEditor
  },
  data () {
    return {

      error: false,
      errorreport: '',
      serv: {},
      showloader: true
    }
  },
  created () {
    this.loadinfo()
  },
  methods: {
  loadinfo () {
    this.$http.get('/cmsmanagement/serviceinfo/' + this.$route.params.eid).then((res) => {
      this.serv = res.data.details[0]
      this.showloader = false
    })
  },
  updatepersonalinfo () {
    this.showloader = true
    this.$validator.validateAll().then((result) => {
      if (result) {

            this.$http.put('/cmsmanagement/updateservice/' + this.$route.params.eid, this.serv).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'Service info successfully updateded',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/service_info/' + this.$route.params.eid)
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
      }  else {
        this.showloader = false
      }
    })
  }
}
}
</script>

<style lang="css" scoped>

</style>

import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login Page'
import Newteammember from '@/components/new_teammember'
import Teammembers from '@/components/team_members'
import Blogs from '@/components/blogs'
import Newblog from '@/components/new_blog'
import Memberinfo from '@/components/teammember_info'
import Bloginfo from '@/components/blog_info'
import Editteammember from '@/components/edit_teammember'
import Editblog from '@/components/edit_blog'
import Services from '@/components/services'
import Newservice from '@/components/new_service'
import Serviceinfo from '@/components/service_info'
import Editservice from '@/components/edit_service'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'LoginPage',
      component: Login
    },
    {
      path: '/new_teammember',
      name: 'NewTeamMember',
      component: Newteammember
    },
    {
      path: '/team_members',
      name: 'TeamMembers',
      component: Teammembers
    },
    {
      path: '/member_info/:id',
      name: 'member_info',
      component: Memberinfo
    },
    {
      path: '/edit_teammember/:eid',
      name: 'editteammember',
      component: Editteammember
    },
    {
      path: '/blogs',
      name: 'Blogs_list',
      component: Blogs
    },
    {
      path: '/new_blog',
      name: 'NewBLog',
      component: Newblog
    },
    {
      path: '/blog_info/:id',
      name: 'blog_info',
      component: Bloginfo
    },
    {
      path: '/edit_blog/:eid',
      name: 'editblog',
      component: Editblog
    },
    {
      path: '/services',
      name: 'Services_list',
      component: Services
    },
    {
      path: '/new_service',
      name: 'NewService',
      component: Newservice
    },
    {
      path: '/service_info/:id',
      name: 'service_info',
      component: Serviceinfo
    },
    {
      path: '/edit_service/:eid',
      name: 'editservice',
      component: Editservice
    },
  ]
})

<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
      <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>Edit Team Member</h2>
      </div>
      <div class="flexcircle">
        <router-link :to="{ name: 'member_info', params: {id: $route.params.eid}}" class="btn btn-gray btn-subheader">Back</router-link>
        <!-- <button type="button" class="btn btn-vermilion btn-subheader">Add Physician</button> -->
      </div>
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="updatepersonalinfo">
        <div class="errorrow">
          <!-- <pre>{{errors}}</pre> -->
          <!-- <div class="errorsmall" v-if="signup.submitted && personalinfo.loginid.$error.pattren">Loginid is not valid</span>
            <span class="error errorbatch" ng-show="signup.submitted && personalinfo.loginid.$error.maxlength">Loginid is Exceded 20 Characters </span> -->
          <span class="errorsmall" v-show="error">{{errorreport}}</span>

        </div>
        <div class="row">

          <div class="col-sm-6 mb20">
            <label>First Name</label>
              <input type="text" class="form-control" placeholder="First Name" name="First Name" id="fname" :class="{ 'is-danger': errors.has( 'First Name')}" v-validate="'required'" v-model="member.first_name">
              <span class="error_text" v-if="errors.has('First Name')">{{errors.first('First Name')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Last Name</label>
            <!-- <span class="errorsmall" v-if="emailidavail != ''">{{emailidavail}}</span> -->
              <input type="text" class="form-control" placeholder="Last Name" name="Last name" id="lname" v-model="member.last_name">
          </div>
          <div class="col-sm-6 mb20">
            <label>Designation</label>
            <!-- <span class="errorsmall" v-if="emailidavail != ''">{{emailidavail}}</span> -->
              <input type="text" class="form-control" placeholder="Designation" name="Designation" id="designation" v-model="member.designation">
          </div>
          <div class="col-sm-6 mb20">
            <label>Email</label>
              <input type="email" class="form-control" placeholder="Email Id"  name="email id" id="emailid" v-model="member.email">
          </div>
          <div class="col-sm-6 mb20">
            <label>Phone No</label>
              <input type="text" class="form-control" placeholder="Phone No"  name="phone no" id="phoneno" v-model="member.phone">
          </div>
          <div class="col-sm-6 mb20">
            <label>Linked In</label>
              <input type="text" class="form-control" placeholder="Linked In"  name="linked in" id="linkedin" v-model="member.linkedin">
          </div>

          <div class="col-12 mb-5">
            <label>About</label>
            <vue-editor
    :customModules="customModulesForEditor"
    :editorOptions="editorSettings"
    v-model="member.about"
    name="Info" id="info"
    :class="{ 'is-danger': errors.has( 'Info')}" v-validate="'required'"
  >
  </vue-editor>
  <span class="error_text" v-if="errors.has('Info')">{{errors.first('Info')}}</span>
          </div>

<div class="col-12 mb20">
  <div class="flexrow ">
    <div class="subheader_flex">
      <h2>Profile Image </h2>
    </div>
    <div class="flexcircle">
      <div class="upload-btn-wrapper">
        <button class="btn btn-green">Edit Profile Image</button>
        <input type="file" id="files" ref="files"  v-on:change="handleFilesUpload($event)" />
      </div>
    </div>
  </div>
  <div class="col-12" v-if="files.length == 0">
    <img :src="member.image"   class="imagewidth img-fluid" width="300" height="300" v-on:click="$refs.files.click()" />
  </div>
  <div class="clearfix col-12 filebox profileimg" v-if="showstrsec == true" >
    <div class="uploadmess text-center" v-if="files.length === 0" v-on:click="$refs.files.click()">
      <font-awesome-icon icon="upload" size="6x" />
    </div>

      <div class="flexrow fixeddim profilefixeddim" v-for="(file, key) in files" v-bind:key="key">
        <!-- <div class="filename subheader_flex ">{{ file.filee.name }} </div> -->
        <img :ref="'image'" class="img-fluid"/>
        <div class="file_btn flexcircle">
          <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
        </div>
      </div>

  </div>
</div>
          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";
export default {
  name: 'edit_teammember',
  components: {
    VueEditor
  },
  data () {
    return {
      customModulesForEditor: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "imageResize", module: ImageResize }
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      error: false,
      errorreport: '',
      member: {},
      files: [],
      showloader: true,
      showstrsec: false
    }
  },
  created () {
    this.loadinfo()
  },
  methods: {
  loadinfo () {
    this.$http.get('/cmsmanagement/tminfo/' + this.$route.params.eid).then((res) => {
      this.member = res.data.details[0]

      this.showloader = false
    })
  },
  updatepersonalinfo () {
    this.showloader = true
    this.$validator.validateAll().then((result) => {
      if (result) {
        if (this.files.length !== 0) {
          let formData = new FormData()
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
                  alert(this.files[j])
              }
            }

            formData.append('first_name', this.member.first_name);
            formData.append('last_name', this.member.last_name);
                formData.append('designation', this.member.designation);
            formData.append('email', this.member.email);
            formData.append('phone', this.member.phone);
            formData.append('linkedin', this.member.linkedin);
            formData.append('about', this.member.about);
              this.$http.put('/cmsmanagement/updateteammember/'+this.$route.params.eid,  formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'Team Member info successfully updateded',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/member_info/' + this.$route.params.eid)
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
          } else {
            this.$http.put('/cmsmanagement/updatetmwithoutimage/' + this.$route.params.eid, this.member).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message:'Team Member info successfully updateded',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/member_info/' + this.$route.params.eid)
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
          }
      }  else {
        this.showloader = false
      }
    })
  },
  handleFilesUpload () {
    this.showstrsec = true
    // this.files = []
    let uploadedFiles = this.$refs.files.files
    console.log('test', uploadedFiles)
    /*
      Adds the uploaded file to the files array
    */
    for (var i = 0; i < uploadedFiles.length; i++) {
      this.files.push(uploadedFiles[i])
      console.log(this.files)
    }

    for (let i = 0; i < this.files.length; i++) {
      let reader = new FileReader();
     reader.onload = (e) => {
        this.$refs.image[i].src = reader.result;
console.log(e)
      };

      reader.readAsDataURL(this.files[i]);

    }
  },
  removeFile (key) {
      this.showstrsec = false
    this.files.splice(key, 1)
  }
}
}
</script>

<style lang="css" scoped>

</style>

<template>
  <div id="app">

    <!-- <Navbar v-if="$route.name !== 'Login' && $route.name !== undefined"></Navbar> -->
    <div class="apppage">

      <header class="hiddensm sideheaderdiv" v-if="$route.name !== 'LoginPage' && $route.name !== undefined">
        <div class="sidebox">
          <div class="sb_logobox">
            <img src="@/assets/nmlogo.png" class="img-fluid" alt="">
          </div>
          <div class="menu">
            <router-link  to="/team_members" class="menuitem"><font-awesome-icon icon="sitemap" size="1x" /> <span>Team Members</span></router-link>
            <router-link  to="/services" class="menuitem"><font-awesome-icon icon="edit" size="1x" /> <span>Services</span></router-link>
              <router-link  to="/blogs" class="menuitem"><font-awesome-icon icon="edit" size="1x" /> <span>Blogs</span></router-link>

            <router-link  to="to"  @click.native="logout" class="menuitem"><font-awesome-icon icon="sign-out-alt" size="1x" /> <span>Logout</span></router-link>
          </div>
        </div>
      </header>
      <div class="routbox" v-bind:class="{ 'routbox1': $route.path == '/' }">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
// import Navbar from '@/components/Navbar'


export default {
  name: 'app',
  data () {
    return {
      showmenu: false
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getMenu',
      'getToken'
    ])
  },
  methods: {
    ...mapActions([
      'clearAll',
      'setMenu'
    ]),
    menu () {
      this.setMenu(!this.getMenu)
    },
    logout () {
      // this.showloader = true
      this.userInfo = ''
      this.token = ''
      this.clearAll()
      localStorage.removeItem('token')
      localStorage.removeItem('vuex')
      localStorage.removeItem('userInfo')
      this.$router.push('/')
      clearInterval(this.interval)
    },
  },
  // components: {
  //   Navbar
  // }
}
</script>


<style>
/* @import '../node_modules/bootstrap/scss/bootstrap.scss' */
</style>


//
// new Vue({
//   render: h => h(App),
// }).$mount('#app')
import Vue from 'vue'
import App from './App'
import router from './router'
import axios from './backend/vue-axios'
import store from './store/auth'
import 'bootstrap/dist/css/bootstrap.css';
import VeeValidate from 'vee-validate'
import Vue2Filters from 'vue2-filters'
import VueFloatLabel from 'vue-float-label'
import moment from 'moment'
import Toasted from 'vue-toasted';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
import 'vue-loaders/dist/vue-loaders.css'
import VueLoaders from 'vue-loaders'
import VueGoodTable from 'vue-good-table'
import VuejsDialog from 'vuejs-dialog'
// import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components


// import edivtemp from '@/components/Ediv Temp'
// import the styles
import CKEditor from '@ckeditor/ckeditor5-vue'
import 'vue-good-table/dist/vue-good-table.css'
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import './assets/css/default.css'
import './assets/css/responsive.css'

Vue.use(VueGoodTable)

Vue.use(VueLoaders)
Vue.use(Vue2Filters)
// Global declaration for VuejsDialog
Vue.use(VuejsDialog, {
  loader: true
})

Vue.use(VueFloatLabel)
Vue.use(VeeValidate)
Vue.use(Toasted)
Vue.use(VueToast);
library.add(fas)
// fontawesome.library.add(brands)
Vue.use(CKEditor)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Vue.component('ediv temp', edivtemp)

// Vue.component('paginated-list', paginatedlist)
Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('MMM DD YYYY hh:mm a')
  }
});
Vue.filter('formatDate1', (value) => {
  if (value) {
    return moment(String(value)).format('MMM DD YYYY')
  }
})


Vue.prototype.$imageUrl = 'https://dmonks.studio:9000/'
Vue.prototype.$globalUrl = 'https://dmonks.studio:9000/api/v1/pgmanagement/downloadexcel'

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  axios,
  store,
  template: '<App/>',
  components: { App }
})

<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
        <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>New Blog</h2>
      </div>
      <div class="flexcircle">
        <router-link :to="{ name: 'Blogs_list'}" class="btn btn-gray btn-subheader">Back</router-link>
      </div>
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="addphotographer">
        <div class="errorrow">
          <span class="errorsmall" v-show="error">{{errorreport}}</span>
        </div>
        <div class="row">
          <div class="col-sm-6 mb20">
            <label>Title</label>
              <input type="text" class="form-control" placeholder="Title" name="title" id="title" :class="{ 'is-danger': errors.has( 'Blog Title')}" v-validate="'required'" v-model="blog.title">
              <span class="error_text" v-if="errors.has('Blog Title')">{{errors.first('Blog Title')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Category</label>
              <multiselect v-model="selectedObjects" tag-placeholder="Add this as new category" placeholder="Search or add a category" label="name" track-by="name" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
          </div>
          <div class="col-12 mb-5">
            <label>Content</label>
            <vue-editor
    :customModules="customModulesForEditor"
    :editorOptions="editorSettings"
    v-model="blog.content"
    name="Blog Content" id="content"
  >
  </vue-editor>
          </div>
          <div class="col-12 mb20">
            <div class="flexrow ">
              <div class="subheader_flex">
                <h2>Add Media</h2>
              </div>
              <div class="flexcircle">
                <div class="upload-btn-wrapper">
                  <button class="btn btn-green">Upload Image / Files</button>
                  <input type="file" id="files" ref="files" accept="application/pdf,image/tiff,image/jpeg,image/x-png,video/mp4,video/x-m4v" v-on:change="handleFilesUpload($event)" />
                </div>
              </div>
            </div>
            <div class="clearfix col-12 filebox " >
              <div class="uploadmess text-center" v-if="files.length === 0" v-on:click="$refs.files.click()">
                Please click here to browse media files
              </div>

                <div class="file-listing clearfix justify-content-center bg-transparent" v-for="(file, key) in files" v-bind:key="key">
                  <div class="flexrow fixeddim">
                    <img :ref="'image'" class="img-fluid"/>
                    <video id="preview" width="600" :ref="'video'"  controls v-hide="videodiv">
  Your browser does not support HTML5 video.
</video>
                    <div class="file_btn flexcircle">
                      <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
                    </div>
                  </div>

<div class="filename subheader_flex pt-3" v-if="file.type == 'application/pdf'">{{ file.name }} </div>
              </div>

            </div>
          </div>

          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";
import Multiselect from 'vue-multiselect'
export default {
  name: 'NewBlog',
  components: {
    VueEditor,
     Multiselect
  },
  data () {
    return {
      content: '',
     customModulesForEditor: [
       { alias: "imageDrop", module: ImageDrop },
       { alias: "imageResize", module: ImageResize }
     ],
     editorSettings: {
       modules: {
         imageDrop: true,
         imageResize: {}
       }
     },
     options: [],
      error: false,
      errorreport: '',
      blog: {
        title: '',
        blogtype: [],
        content: ''
      },
        files: [],
      showloader: false,
      selectedObjects: [],
      selectedcategories: [],
      videodiv: true
    }
  },
  watch: {
  selectedObjects(newValues) {
    this.selectedcategories = newValues.map(obj => obj.name);
  }
},
created () {
  this.loadusers()
},
  methods: {
    loadusers () {
      this.photographers = []
      this.$http.get('/cmsmanagement/getblogcategories').then((res) => {
        if (res.data.success == false) {
          console.log('error')
        } else {
          let array = res.data.details.map(obj => obj.category)
          console.log(array)
          this.options = array.map(name => ({...this.options, name}));
          console.log(this.options)
        }

      this.showloader = false
      })
    },
    addTag (newTag) {
     const tag = {
       name: newTag
     }
     this.options.push(tag)
     this.selectedObjects.push(tag)
   },
    addphotographer () {
      this.showloader = true
this.blog.blogtype = this.selectedcategories
      this.$validator.validateAll().then((result) => {
        if (result) {
          let formData = new FormData()
            if (this.files.length !== 0) {
               for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
              formData.append('title', this.blog.title);
              formData.append('content', this.blog.content);
              formData.append('blogtype', this.blog.blogtype);
            this.$http.post('/cmsmanagement/addblog',  formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'New Blog added successfully',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/blogs')
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            })
          } else {
            this.$http.post('/cmsmanagement/addblogwithoutattachment',  this.blog).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'New Blog added successfully',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/blogs')
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            });
          }

        }  else {
          this.showloader = false
        }
      })
    },
    handleFilesUpload () {
      // this.files = []
      let uploadedFiles = this.$refs.files.files
      /*
        Adds the uploaded file to the files array
      */
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }
      for (let i = 0; i < this.files.length; i++) {
        let reader = new FileReader();
       reader.onload = (e) => {
         var extension = this.files[i].name.split('.').pop().toLowerCase();
         if (extension !== 'pdf' && extension !== 'doc' && extension !== 'mp4' && extension !== 'm4v') {
             this.$refs.image[i].src = reader.result;
         } else if(extension == 'pdf') {
           this.$refs.image[i].src = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/195px-PDF_file_icon.svg.png";
         } else if(extension == 'mp4' || extension == 'm4v') {
           console.log('testvideo')
this.videodiv = false
            this.$refs.video[i].src = reader.result

         }

console.log(e)
        };

        reader.readAsDataURL(this.files[i]);
      }
    },
    removeFile (key) {
      this.files.splice(key, 1)
    }
  }
}
</script>

<style lang="css" scoped>

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
        <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>New Service</h2>
      </div>
      <div class="flexcircle">
        <router-link :to="{ name: 'Blogs_list'}" class="btn btn-gray btn-subheader">Back</router-link>
      </div>
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="addphotographer">
        <div class="errorrow">
          <span class="errorsmall" v-show="error">{{errorreport}}</span>
        </div>
        <div class="row">
          <div class="col-sm-12 mb20">
            <label>Title</label>
              <input type="text" class="form-control" placeholder="Title" name="title" id="title" :class="{ 'is-danger': errors.has( 'Service Title')}" v-validate="'required'" v-model="serv.title">
              <span class="error_text" v-if="errors.has('Service Title')">{{errors.first('Service Title')}}</span>
          </div>

          <div class="col-12 mb-5">
            <label>Focused Areas</label>
            <vue-editor
    v-model="serv.focusedareas"
    name="Focused Areas" id="focusedareas" class="focusar"
  >
  </vue-editor>
          </div>


          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: 'NewBlog',
  components: {
    VueEditor
  },
  data () {
    return {
      content: '',

      error: false,
      errorreport: '',
      serv: {
        title: '',
        focusedareas: ''
      },
      showloader: false
    }
  },
  methods: {
    addphotographer () {
      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {

            this.$http.post('/cmsmanagement/addservice',  this.serv).then((response) => {
              if (response.data.success === true) {
                // setTimeout(function() {
                  this.$toast.open( {
                    message: 'New Service added successfully',
               position: 'top-right',
               duration: 5000,
               type: 'success'
               })

                       // }, 2000);
                  this.$router.push('/services')
              } else {
                this.showloader = false
                this.error = true
                this.errorreport = response.data.error
              }
            });


        }  else {
          this.showloader = false
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>

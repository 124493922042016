// /* global localStorage */

import axios from 'axios'

// const API_URL = process.env.API_URL || 'http://localhost:9000/api/v1'
const API_URL = process.env.API_URL || 'https://dmonks.studio:9000/api/v1'
const instance = axios.create({
  baseURL: API_URL,
  timeout: 0,
  params: {}
})

instance.interceptors.request.use(function(config) {
    // this.loading = true
    var token = localStorage.token
    if (token != null) {
      config.headers['authorization'] = 'Bearer ' + token
      config.headers['x-access-token'] = token
    }
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  })

export default instance

<template>

<div class="cards">
  <div class="inprocessdiv" v-show="showloader">
      <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
  </div>
  <div class="flexrow_green mb50" >
    <div class="subheader_flex">
      <h1 class="nomargin redhigh">Blogs</h1>
    </div>
    <div class="flexcircle">
      <router-link to="/new_blog"  class="btn btn-vermilion">Add</router-link>
    </div>
  </div>
   <vue-good-table
  :columns="columns"
  :rows="photographers"

  :pagination-options="{
    enabled: true,
    perPage: 10,
  }"
  styleClass="vgt-table striped bordered" theme="black-rhino" @on-row-click="onRowClick"/>

</div>

</template>

<script>
import moment from 'moment'
export default {
  name: 'Blogs_list',
  data () {
    return {
      columns: [
        {
          label: 'Blog Title',
          field: 'title'
        },
        {
          label: 'Blog Content',
          field: 'content',
          html: true,
          tdClass: 'blogslist'
        },
        {
          label: 'Blog Category',
          field: 'blogtype',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Updated Date',
          field: 'updated_at',
          type: 'date',
    formatFn: function (value) {
        return value != null ? moment(value).format('LL') : null
    }
        }
      ],
      photographers: [],
      showloader: true
    }
  },
  created () {
    this.loadusers()
  },
  methods: {
    onRowClick (params) {
        this.$router.push('/blog_info/' + params.row.blog_id )
    },
    loadusers () {
      this.photographers = []
      this.$http.get('/cmsmanagement/getblogs').then((res) => {
        this.photographers = res.data.details;


        this.showloader = false
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>

<template>
<div class="cards">
  <div class="inprocessdiv" v-show="showloader">
    <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
  </div>
  <div class="boxsh">
    <div class="flexrow_green mt20">
      <div class="subheader_green_flex">
        <h2>Team Member Info</h2>
      </div>
      <div class="flexcircle">

        <a @click="$router.push('/team_members')" class="btn btn-gray btn-subheader">Back</a>
        <!-- <button type="button" class="btn btn-vermilion btn-subheader">Add Physician</button> -->
      </div>
    </div>
    <div class="cardinner">
      <div class="row ">

          <div class="col-sm-4 mb20">
            <label> First Name:</label>
            <span class="spanblock">{{member.first_name}}</span>
          </div>
          <div class="col-sm-4 mb20">
            <label> Last Name:</label>
            <span class="spanblock">{{member.last_name}}</span>
          </div>
          <div class="col-sm-4 mb20">
            <label> Designation:</label>
            <span class="spanblock">{{member.designation}}</span>
          </div>
          <div class="col-4 mb20">
            <label> Email:</label>
            <span class="spanblock">{{member.email}}</span>
          </div>
          <div class="col-4 mb20">
            <label> Phone No:</label>
            <span class="spanblock">{{member.phone}}</span>
          </div>
          <div class="col-4 mb20">
            <label> LinkedIn:</label>
            <span class="spanblock">{{member.linkedin}}</span>
          </div>
          <div class="col-sm-12 mb20">
            <label> About :</label>
            <span class="spanblock" v-html="member.about"></span>
          </div>
          <div class="col-sm-12 mb20">
            <img :src="member.image" alt="image"  class="imagewidth img-fluid" width="250" height="250" />
          </div>



        <div class="col-12 text-right">

           <button type="button" class="btn btn-green mr-3" name="button" v-on:click="activate(member)" v-if="member.status == 'inactive'">Active</button>
          <button type="button" class="btn btn-vermilion mr-3" name="button" v-on:click="suspend(member)" v-if="member.status == 'active'">Suspend</button>

          <router-link :to="{ name: 'editteammember', params: { eid: member.team_id }}"  class="btn btn-vermilion">Edit Info</router-link>
        </div>
      </div>
    </div>
  </div>


</div>
</template>

<script>
// import { mapGetters } from 'vuex'
  import _ from 'lodash';
export default {
  name: 'member_info',
  data () {
    return {
      member: {
      },
      images: [],
      status: '',
      showloader: true

    }
  },
  mounted () {
    this.loadinfo()
  },
  methods: {
    sortArrays(arrays) {
            return _.orderBy(arrays, 'created_at');
        },
    loadinfo () {
      this.$http.get('/cmsmanagement/tminfo/' + this.$route.params.id).then((response) => {
        if (response.data.success === true) {
          this.showloader = false
          this.member = response.data.details[0]

        } else {
          this.showloader = false
          this.member = []
        }
      })
    },

    // For suspend the Channel Partner...
      suspend (cpat) {
        let messagee = "Are you sure you want to Suspend <b>" + cpat.first_name + '</b> from Team Members?'
        this.$dialog.confirm(messagee,{
          loader: true,
          okText: "Proceed",
          cancelText: "Cancel",
          html: true
        })
        .then((dialog) => {
          this.$http.put('/cmsmanagement/updatestatus/' + cpat.team_id, {
            status: "inactive"
          }).then((res) => {
            console.log(res)
        
            // setTimeout(function() {
              this.$toast.open( {
                message: '<b>'+ cpat.first_name +'</b> successfully remove from Team Members ',
           position: 'top-right',
           duration: 5000,
           type: 'success'
           })

                   // }, 2000);
            this.loadinfo()
            dialog.close()
          })
        })
        .catch(() => {
          //console.log(this.$i18n.t('dataarray.xa271'))
        })
      },

    // For activate the Channel Partner...
    activate (cpat) {
      let messagee = "Are you sure you want to  make <b>" + cpat.first_name + '</b> as a Team Member?'
      this.$dialog.confirm(messagee,{
        loader: true,
        okText: "Proceed",
        cancelText: "Cancel",
        html: true
      })
      .then((dialog) => {
        this.$http.put('/cmsmanagement/updatestatus/' + cpat.team_id, {
          status: "active"
        }).then((res) => {
          console.log(res)

          // setTimeout(function() {

            this.$toast.open({
              message: '<b>'+ cpat.first_name +'</b> successfully added to team',
type: 'success',
         position: 'top-right',
         duration: 5000
         })

                 // }, 2000);
          this.loadinfo()
          dialog.close()
        })
      })
      .catch(() => {
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

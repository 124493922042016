<template>

<div class="cards">
  <div class="inprocessdiv" v-show="showloader">
      <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
  </div>
  <div class="flexrow_green mb50" >
    <div class="subheader_flex">
      <h1 class="nomargin redhigh">Team Members</h1>
    </div>
    <div class="flexcircle">
      <router-link to="/new_teammember"  class="btn btn-vermilion">Add</router-link>
    </div>
  </div>
   <vue-good-table
  :columns="columns"
  :rows="photographers"

  :pagination-options="{
    enabled: true,
    perPage: 10,
  }"
  styleClass="vgt-table striped bordered " theme="black-rhino" @on-row-click="onRowClick"/>

</div>

</template>

<script>
export default {
  name: 'TeamMembers',
  data () {
    return {
      columns: [
        {
          label: 'Profile Image',
          field: 'image',
          html: true,

        },
        {
          label: 'Full Name',
          field: 'fullname',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'About',
          field: 'about',
          html: true,
          tdClass: 'testclass'
        }
      ],
      photographers: [],
      showloader: true
    }
  },
  created () {
    this.loadusers()
  },
  methods: {
    onRowClick (params) {
        this.$router.push('/member_info/' + params.row.team_id )
    },
    loadusers () {
      this.photographers = []
      this.$http.get('/cmsmanagement/getteamembers').then((res) => {
        if (res.data.success === false) {
          this.photographers = []
        } else {
          this.photographers = res.data.details.map(o=>({
        ...o,
        image:`<img width="100" height="100" src="${o.image}"/>`

      }))
        }



        this.showloader = false
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>
